import superagent from 'superagent';

import withApiPrefix from 'services/api/withApiPrefix.middleware';
import withToken from 'services/auth/withToken.middleware';
import withLocale from 'services/api/withLocale.middleware';
// import withGraviteeHeadersInLocal from 'services/auth/withGraviteeHeaderInLocal.middleware';

const agent = superagent.agent();

agent.use(withApiPrefix);
agent.use(withLocale);
agent.use(withToken);

// Uncomment only if you want to test back in localhost
// In environement, Gravitee add automaticaly these headers.
// agent.use(withGraviteeHeadersInLocal);

export default agent;
