import makeLocalStorageAdapter from 'utils/storage/localStorageAdapter';

const LOCALE_STORAGE_KEY = 'locale';

const localStorageAdapter = makeLocalStorageAdapter(LOCALE_STORAGE_KEY);

/**
 * Looks for the presence of the locale in the storage.
 *
 * @returns {string|undefined} - The locale if found, undefined otherwise.
 */
export const getStoredLocale = () => localStorageAdapter.get();

/**
 * Stores locale in the storage.
 *
 * @param {string} locale - The locale to store.
 */
export const storeLocale = (locale) => { localStorageAdapter.set(locale); };
