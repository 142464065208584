import React from 'react';

export const removeTrailingSlash = str => (str ? str.replace(/\/$/, '') : '');

const CODE = 'code=';
export const getCodeFromUrl = url => (
  url.substring(url.indexOf(CODE) + CODE.length, url.indexOf('&'))
);

/**
 * Bold the brand in ticket.
 *
 * @param {object} ticket - A ticket.
 * @returns {object} - Label formated.
 */
export const boldTheBrand = (ticket) => {
  const brandTemp = ticket.product?.brand || '';
  const labelTemp = ticket.product?.label || '';
  const labelTab = labelTemp.split(' ');
  const label = labelTab.map((strLabel) => {
    if (brandTemp.includes(strLabel)) {
      return <p key={strLabel} id="toBolder">{strLabel}&nbsp;</p>;
    }
    return <p key={strLabel}>{strLabel}&nbsp;</p>;
  });
  return label;
};
