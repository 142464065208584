export const emptyObject = {};

export const getEmptyObject = () => emptyObject;

/**
 * Transfrom error string to object.
 *
 * @param {string} error - Error from Back.
 * @returns {Array<string>} - Array of string contains error.
 */
export const getErrorFromBack = (error) => {
  const errorTemp = JSON.stringify(error); // transf les infos en string
  const json = JSON.parse(errorTemp); // transf les infos en objet
  const text = json?.response?.body?.errors; // récup de l'info erreur contenu dans objet
  return text;
};
