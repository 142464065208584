import * as UserPreferencesRepository from 'domain/userPreferences/repository';
import { ACTIONS } from './constants';

export const FETCH_USER_PREF_LOADING = userId => ({
  type: ACTIONS.FETCH_USER_PREF_LOADING,
  payload: {
    userId,
  },
});

export const FETCH_USER_PREF_SUCCESS = (userId, userPreferences) => ({
  type: ACTIONS.FETCH_USER_PREF_SUCCESS,
  payload: {
    userId,
    userPreferences,
  },
});

export const FETCH_USER_PREF_FAILURE = (err, userId) => ({
  type: ACTIONS.FETCH_USER_PREF_FAILURE,
  error: true,
  payload: {
    err,
    userPreferences: {
      id: userId,
      hierarchieIds: [],
      notifyWithdrawalTicket: true,
      notifyTicket: true,
    },
  },
});

export const fetchUserPreferences = userId => async (dispatch) => {
  dispatch(FETCH_USER_PREF_LOADING(userId));
  try {
    const userPreferences = await UserPreferencesRepository.fetchUserPreferences(userId);
    return dispatch(FETCH_USER_PREF_SUCCESS(userId, userPreferences));
  } catch (err) {
    return dispatch(FETCH_USER_PREF_FAILURE(err, userId));
  }
};

export const POST_USER_PREF_LOADING = userId => ({
  type: ACTIONS.POST_USER_PREF_LOADING,
  payload: {
    userId,
  },
});

export const POST_USER_PREF_SUCCESS = (userId, userPreferences) => ({
  type: ACTIONS.POST_USER_PREF_SUCCESS,
  payload: {
    userId,
    userPreferences,
  },
});

export const POST_USER_PREF_FAILURE = err => ({
  type: ACTIONS.POST_USER_PREF_FAILURE,
  error: true,
  payload: {
    err,
  },
});

export const postUserPreferences = (userId, userPreferences) => async (dispatch) => {
  dispatch(POST_USER_PREF_LOADING(userId));
  try {
    await UserPreferencesRepository.postUserPreferences(userId, userPreferences);
    return dispatch(POST_USER_PREF_SUCCESS(userId, userPreferences));
  } catch (err) {
    return dispatch(POST_USER_PREF_FAILURE(err));
  }
};
