import config from 'config/env';

export default (key) => {
  const prefixedKey = `${config.app.name}:${key}`;
  return ({
    key: prefixedKey,
    get: () => localStorage.getItem(prefixedKey),
    set: value => localStorage.setItem(prefixedKey, value),
    remove: () => localStorage.removeItem(prefixedKey),
  });
};
