import agent from 'services/http/api';

export const fetchUserPreferences = async (userId) => {
  const { body } = await agent.get(`/users/${userId}/preferences`);

  return body;
};

export const postUserPreferences = async (userId, userData) => {
  const { body } = await agent.post(`/users/${userId}/preferences`).send(userData);

  return body;
};
