export const ACTIONS = {
  FETCH_TICKETS_LOADING: 'tickets/fetch:loading',
  FETCH_TICKETS_SUCCESS: 'tickets/fetch:success',
  FETCH_TICKETS_FAILURE: 'tickets/fetch:failure',

  FETCH_TICKET_LOADING: 'ticket/fetch:loading',
  FETCH_TICKET_SUCCESS: 'ticket/fetch:success',
  FETCH_TICKET_FAILURE: 'ticket/fetch:failure',

  FETCH_WITHDRAWALTICKETS_LOADING: 'withdrawalTickets/fetch:loading',
  FETCH_WITHDRAWALTICKETS_SUCCESS: 'withdrawalTickets/fetch:success',
  FETCH_WITHDRAWALTICKETS_FAILURE: 'withdrawalTickets/fetch:failure',

  UPDATE_TICKET_LOADING: 'ticket/update:loading',
  UPDATE_TICKET_SUCCESS: 'ticket/update:success',
  UPDATE_TICKET_FAILURE: 'ticket/update:failure',

  POST_FLASHING_LIGHT_LOADING: 'ticket/flashing:loading',
  POST_FLASHING_LIGHT_SUCCESS: 'ticket/flashing:success',
  POST_FLASHING_LIGHT_ERROR: 'ticket/flashing:failure',

  DELETE_TICKET_LOADING: 'ticket/delete:loading',
  DELETE_TICKET_SUCCESS: 'ticket/delete:success',
  DELETE_TICKET_FAILURE: 'ticket/delete:failure',

  UPDATE_LIST_OF_TICKETS_LOADING: 'listOfTickets/update:loading',
  UPDATE_LIST_OF_TICKETS_SUCCESS: 'listOfTickets/update:success',
  UPDATE_LIST_OF_TICKETS_FAILURE: 'listOfTickets/update:failure',

  UPDATE_WITHDRAWALTICKET_LOADING: 'withdrawalTicket/update:loading',
  UPDATE_WITHDRAWALTICKET_SUCCESS: 'withdrawalTicket/update:success',
  UPDATE_WITHDRAWALTICKET_FAILURE: 'withdrawalTicket/update:failure',

  PRINT_TICKET_LOADING: 'tickets/print:loading',
  PRINT_TICKET_SUCCESS: 'tickets/print:success',
  PRINT_TICKET_FAILURE: 'tickets/print:failure',
};
