import agent from 'services/http/notifications/api';
import { APP_TITLE } from 'constants/app';

export const subscribe = async (subscription) => {
  const { body } = await agent
    .post('/subscription')
    .send({
      ...subscription,
      appName: APP_TITLE,
      lang: navigator.language,
      redirectPathCommon: `${window.origin}${window.location.pathname}#`,
    });

  return body;
};

export const update = async (notification, owner) => {
  const { body } = await agent
    .put(`/notification/${notification.id}`)
    .set('Content-Type', 'application/json')
    .send(owner);

  return body;
};

export const updateByEventId = async (ticket, owner) => {
  const eventId = ticket?.withdrawalTicket ? ticket.saleId : ticket.eventId;

  const { body } = await agent
    .put(`/notificationByEventId/${eventId}`)
    .set('Content-Type', 'application/json')
    .send(owner);

  return body;
};
