import { keyBy } from 'lodash';
import { combineReducers } from 'redux';
import { ACTIONS } from './constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.FETCH_HIERARCHIES_SUCCESS:
        return keyBy(action.payload.hierarchies, 'code');
      default:
        return state;
    }
  },

  isLoading: combineReducers({
    fetchHierarchies: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.FETCH_HIERARCHIES_LOADING:
          return state + 1;
        case ACTIONS.FETCH_HIERARCHIES_SUCCESS:
        case ACTIONS.FETCH_HIERARCHIES_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
