import React from 'react';
import ReactDOM from 'react-dom';

import App from 'react/App';
import { setLocale } from 'redux/intl/actions';
import store from 'redux/store';
import AppWrapper from 'react/AppWrapper';

import './style/main.scss';

(async function main() {
  await store.dispatch(setLocale());

  // Start mock server
  // if (process.env.NODE_ENV === 'development') {
  //   await startServer({ environnement: 'development' });
  // }

  // CI/CD use hash router
  // We can't set a redirect uri with an hash (see oauth doc)
  // So we have to rebuild url to redirect to LoginView with hash
  let redirectUrlToLogin = document.location.href;
  if (redirectUrlToLogin.includes('code=') && !window.location.href.includes('/login-from-am')) {
    const { pathname } = window.location;
    redirectUrlToLogin = redirectUrlToLogin.replace(pathname, `${pathname}#/login-from-am#`);
    document.location.href = redirectUrlToLogin;
  } else {
    ReactDOM.render(
      <AppWrapper>
        <App />
      </AppWrapper>,
      document.getElementById('app'),
    );
  }
}());
