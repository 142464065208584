import { notification } from 'antd';

export const NOTIFICATION_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

const notify = ({
  type,
  message,
  description,
  duration = 4.5,
  className = '',
}) => (
  notification[type]({
    message,
    description,
    duration,
    className,
  })
);

export default notify;
