export const mapperUserBFFToFront = (nameUser, userBFF) => ({
  profile: {
    name: nameUser,
    employee_id: userBFF.id,
    site_id: userBFF.siteId,
    tempSiteId: userBFF.tempSiteId,
  },
});

export const mapperUserFrontToBFF = userFront => ({
  id: userFront?.profile.employee_id,
  name: userFront?.profile.name,
  siteId: userFront?.profile.site_id,
  tempSiteId: userFront?.profile.tempSiteId,
});
