import superagent from 'superagent';

import withNotificationsApiPrefix from 'services/api/notifications/withNotificationsApiPrefix.middleware';
import withToken from 'services/auth/withToken.middleware';
import withLocale from 'services/api/withLocale.middleware';

const agent = superagent.agent();

agent.use(withNotificationsApiPrefix);
agent.use(withLocale);
agent.use(withToken);

export default agent;
