import { SESSION_TERMINATED, USER_EXPIRED } from 'redux-oidc';

const initialState = {
  channels: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SESSION_TERMINATED:
    case USER_EXPIRED:
      return initialState;
    default:
      return state;
  }
};
