import config from 'config/env';

import { removeTrailingSlash } from 'utils/string';

const endpoint = removeTrailingSlash(config.api.endpoint);

export default (req) => {
  if (req?.url?.startsWith('/')) {
    req.url = `${endpoint}${req.url}`;
  }
  return req;
};
