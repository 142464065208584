import { combineReducers } from 'redux';
import { ACTIONS } from './constants';

export default combineReducers({
  params: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.FETCH_SETTINGS_SUCCESS:
        return action.payload.settings;

      default:
        return state;
    }
  },

  isLoading: (state = 0, action) => {
    switch (action.type) {
      case ACTIONS.FETCH_SETTINGS_LOADING:
        return state + 1;
      case ACTIONS.FETCH_SETTINGS_SUCCESS:
      case ACTIONS.FETCH_SETTINGS_FAILURE:
        return state - 1;
      default:
        return state;
    }
  },
});
