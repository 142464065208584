import { combineReducers } from 'redux';
import { ACTIONS } from './constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.FETCH_USER_SUCCESS:
      case ACTIONS.POST_USER_SUCCESS: {
        return action.payload.userData;
      }
      default: {
        return state;
      }
    }
  },

  isLoading: combineReducers({
    fetchUserData: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.FETCH_USER_LOADING:
          return state + 1;
        case ACTIONS.FETCH_USER_SUCCESS:
        case ACTIONS.FETCH_USER_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
    postUserData: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.POST_USER_LOADING:
          return state + 1;
        case ACTIONS.POST_USER_SUCCESS:
        case ACTIONS.POST_USER_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
