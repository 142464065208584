import * as NotificationRepository from 'domain/notifications/repository';
import { ACTIONS } from './constants';

export const SUBSCRIBE_SUCCESS = subscription => ({
  type: ACTIONS.SUBSCRIBE_SUCCESS,
  payload: {
    subscription,
  },
});

export const SUBSCRIBE_FAILURE = err => ({
  type: ACTIONS.SUBSCRIBE_FAILURE,
  error: true,
  payload: {
    err,
  },
});

export const subscribe = subscription => async (dispatch) => {
  try {
    await NotificationRepository.subscribe(subscription);
    return dispatch(SUBSCRIBE_SUCCESS(subscription));
  } catch (err) {
    return dispatch(SUBSCRIBE_FAILURE(err));
  }
};

export const UPDATE_NOTIFICATION_SUCCESS = () => ({
  type: ACTIONS.UPDATE_NOTIFICATION_SUCCESS,
});

export const UPDATE_NOTIFICATION_FAILURE = err => ({
  type: ACTIONS.UPDATE_NOTIFICATION_FAILURE,
  error: true,
  payload: {
    err,
  },
});

export const updateNotification = (notification, owner) => async (dispatch) => {
  try {
    await NotificationRepository.update(notification, owner);
    return dispatch(UPDATE_NOTIFICATION_SUCCESS());
  } catch (err) {
    return dispatch(UPDATE_NOTIFICATION_FAILURE(err));
  }
};

export const updateNotificationByEventId = (ticket, owner) => async (dispatch) => {
  try {
    await NotificationRepository.updateByEventId(ticket, owner);
    return dispatch(UPDATE_NOTIFICATION_SUCCESS());
  } catch (err) {
    return dispatch(UPDATE_NOTIFICATION_FAILURE(err));
  }
};

