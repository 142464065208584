import config from 'config/env';

import { createUserManager } from 'redux-oidc';
import { removeTrailingSlash } from 'utils/string';

const settings = {
  authority: `${config.auth?.url}/user/IdPOAuth2/oidc`,
  client_id: config.auth?.clientId,
  redirect_uri: config.auth?.redirectUri,
  //  redirect_uri: window.location.origin,
  // silent_redirect_uri: `${config.auth?.url}`,
  response_type: 'code',
  loadUserInfo: true,
  scope: 'openid full_profile', // 'openid full_profile bl:app.eticket:user bl:app.eticket:multisite'
  revokeAccessTokenOnSignout: true,
  automaticSilentRenew: true,
  post_logout_redirect_uri: config.auth?.redirectUri,
  metadata: {
    issuer: `${config.auth?.url}/user/IdPOAuth2/oidc`,
    authorization_endpoint: `${config.auth?.url}/user/IdPOAuth2/authorize/oidc`,
    userinfo_endpoint: `${config.auth?.url}/user/IdPOAuth2/userinfo/oidc`,
    end_session_endpoint: `${config.auth?.url}/user/IdPOAuth2/logout/oidc?target_url=${config.auth?.redirectUri}`,
    token_endpoint: `${config.auth?.url}/user/IdPOAuth2/token/oidc`,
    jwks_uri: `${config.auth?.url}/user/IdPOAuth2/jwk/oidc`,
  },
};

const onUserChangeUpdateServiceWorker = async (e) => {
  const sw = await navigator.serviceWorker.ready;

  const endpoint = removeTrailingSlash(config?.notifications?.endpoint);

  sw.active.postMessage({
    agent: { user: e },
    endpoint: endpoint.match(/^https/) ? endpoint : `https://${window.location.hostname}${endpoint}`,
  });
};

let userManager = null;
const getUserManager = () => {
  if (!userManager) {
    userManager = createUserManager(settings);
    userManager.events.addUserLoaded(onUserChangeUpdateServiceWorker);
  }
  return userManager;
};

export default getUserManager();
