import store from 'redux/store';
import userManager from 'services/auth/userManager';
import { subscribe } from 'redux/notifications/actions';

export const isAuthenticated = () => store.getState().auth.oidc.user;

export const logout = async () => {
  // Update the subscription to disabled notification for the user on Logout
  const subscription = store.getState().notifications;

  if (subscription && Object.keys(subscription).length !== 0) {
    const user = store.getState().auth.oidc.user?.profile;
    const userData = store.getState().userData?.map?.profile;
    const siteId = userData?.tempSiteId || user?.site_id;
    const employeeId = user?.employee_id;
    await store.dispatch(subscribe({
      ...subscription,
      enabled: false,
      employeeId,
      siteId,
    }));
  }

  return userManager.signoutRedirect();
};
