/**
 * Returns browser defined locale if the browser allows it.
 *
 * @returns {string} - The browser defined locale.
 */
export const getBrowserLocale = () => {
  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    return navigator.languages[0].substr(0, 2);
  }
  if (navigator.userLanguage) {
    // IE only
    return navigator.userLanguage.substr(0, 2);
  }
  if (navigator.language) {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    return navigator.language.substr(0, 2);
  }
  return '';
};

/**
 * Set the lang attribute on the <html> tag.
 *
 * @param {string} locale - Locale to set.
 * @returns {string} - The locale.
 */
export const setHtmlLangAttribute = (locale) => {
  const element = document?.getElementsByTagName('html')?.[0];

  if (!element) {
    return '';
  }

  element.setAttribute('lang', locale);
  return locale;
};
