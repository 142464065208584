import { combineReducers } from 'redux';
import { ACTIONS } from './constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.FETCH_USER_PREF_SUCCESS:
      case ACTIONS.FETCH_USER_PREF_FAILURE:
      case ACTIONS.POST_USER_PREF_SUCCESS: {
        return action.payload.userPreferences;
      }
      default: {
        return state;
      }
    }
  },

  isLoading: combineReducers({
    fetchUserPreferences: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.FETCH_USER_PREF_LOADING:
          return state + 1;
        case ACTIONS.FETCH_USER_PREF_SUCCESS:
        case ACTIONS.FETCH_USER_PREF_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
    postUserPreferences: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.POST_USER_PREF_LOADING:
          return state + 1;
        case ACTIONS.POST_USER_PREF_SUCCESS:
        case ACTIONS.POST_USER_PREF_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
