import { combineReducers } from 'redux';
import { ACTIONS } from './constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.FETCH_ALERTS_SUCCESS:
        return action.payload.alerts;

      default:
        return state;
    }
  },

  isLoading: (state = 0, action) => {
    switch (action.type) {
      case ACTIONS.FETCH_ALERTS_LOADING:
        return state + 1;
      case ACTIONS.FETCH_ALERTS_SUCCESS:
      case ACTIONS.FETCH_ALERTS_FAILURE:
        return state - 1;
      default:
        return state;
    }
  },
});
