import { keyBy } from 'lodash';
import { combineReducers } from 'redux';
import { ACTIONS } from './constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.FETCH_TICKETS_SUCCESS: {
        return keyBy(action.payload.tickets, 'eventId');
      }
      case ACTIONS.FETCH_TICKET_SUCCESS:
      case ACTIONS.UPDATE_TICKET_SUCCESS: {
        return {
          ...state,
          [action.payload.ticketId]: action.payload.ticket,
        };
      }
      case ACTIONS.UPDATE_LIST_OF_TICKETS_SUCCESS: {
        return keyBy(action.payload.tickets, 'eventId');
      }
      case ACTIONS.FETCH_WITHDRAWALTICKETS_SUCCESS: {
        return keyBy(action.payload.withdrawalTickets, 'eventId');
      }
      case ACTIONS.UPDATE_WITHDRAWALTICKET_SUCCESS: {
        return keyBy(action.payload.withdrawalTickets, 'eventId');
      }
      default: {
        return state;
      }
    }
  },

  isLoading: combineReducers({
    fetchTickets: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.FETCH_TICKETS_LOADING:
          return state + 1;
        case ACTIONS.FETCH_TICKETS_SUCCESS:
        case ACTIONS.FETCH_TICKETS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
    fetchTicket: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.FETCH_TICKET_LOADING:
          return state + 1;
        case ACTIONS.FETCH_TICKET_SUCCESS:
        case ACTIONS.FETCH_TICKET_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
    updateTicket: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.UPDATE_TICKET_LOADING:
          return state + 1;
        case ACTIONS.UPDATE_TICKET_SUCCESS:
        case ACTIONS.UPDATE_TICKET_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
    updateListOfTickets: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.UPDATE_LIST_OF_TICKETS_LOADING:
          return state + 1;
        case ACTIONS.UPDATE_LIST_OF_TICKETS_SUCCESS:
        case ACTIONS.UPDATE_LIST_OF_TICKETS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
    fetchWithdrawalTickets: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.FETCH_WITHDRAWALTICKETS_LOADING:
          return state + 1;
        case ACTIONS.FETCH_WITHDRAWALTICKETS_SUCCESS:
        case ACTIONS.FETCH_WITHDRAWALTICKETS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
    updatehWithdrawalTicket: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.UPDATE_WITHDRAWALTICKET_LOADING:
          return state + 1;
        case ACTIONS.UPDATE_WITHDRAWALTICKET_SUCCESS:
        case ACTIONS.UPDATE_WITHDRAWALTICKET_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
