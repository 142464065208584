import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import classNames from './ImpulseSpinner.module.scss';

const ImpulseSpinnerBase = ({
  className,
  overrideClassName,
  overrideClassNames,
}) => (
  <div
    className={cn(
      className,
      classNames.container,
      overrideClassName,
      !overrideClassName && classNames.default,
    )}
  >
    <div className={cn(overrideClassNames.ball, overrideClassNames.ball1)} />
    <div className={cn(overrideClassNames.ball, overrideClassNames.ball2)} />
    <div className={cn(overrideClassNames.ball, overrideClassNames.ball3)} />
  </div>
);

ImpulseSpinnerBase.displayName = 'ImpulseSpinnerBase';

ImpulseSpinnerBase.propTypes = {
  className: PropTypes.string,
  overrideClassName: PropTypes.string,
  overrideClassNames: PropTypes.shape(),
};

ImpulseSpinnerBase.defaultProps = {
  className: null,
  overrideClassName: null,
  overrideClassNames: classNames,
};

export default memo(ImpulseSpinnerBase);
