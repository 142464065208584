import React from 'react';
import PropTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';
import { OidcProvider } from 'redux-oidc';
import { Provider as ReduxProvider } from 'react-redux';
import { HashRouter as Router, hashHistory } from 'react-router-dom';

import userManager from 'services/auth/userManager';

import IntlProvider from 'react/generic/intl/intl-provider/IntlProvider';
import store from 'redux/store';

const AppWrapper = ({ children }) => (
  <HelmetProvider>
    <ReduxProvider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <IntlProvider>
          <Router history={hashHistory}>
            {children}
          </Router>
        </IntlProvider>
      </OidcProvider>
    </ReduxProvider>
  </HelmetProvider>
);

AppWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AppWrapper;
